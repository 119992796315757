import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import NewsletterBanner from "../../components/newsletterBanner";

import { stereaImagePositionToCSS } from "../../utils/imageManipulation";
import StereaImage from "../../components/stereaImage";

export const query = graphql`
  query($id: String) {
    strapiPage(id: { eq: $id }) {
      id
      Titolo
      Permalink
      Contenuto
      contenuto
      categoria {
        Name
      }
      posizione_copertina
      Copertina {
        alternativeText
        localFile {
          absolutePath
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 90
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`;

const SinglePage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={data.strapiPage.Titolo}
        description={data.strapiPage.Contenuto?.replace(/<\/?[^>]+(>|$)/g, "")}
        image={`https://stereamedicaltravel.it${getSrc(
          data.strapiPage?.Copertina?.localFile
        )}`}
      />

      <div className="sterea-center-column-wrapper overflow-hidden relative">
        <div className="color-overlay absolute w-full h-full bg-sterea-dark-blue bg-opacity-40 z-20"></div>
        <GatsbyImage
          image={getImage(data.strapiPage?.Copertina?.localFile)}
          style={{ position: "absolute" }}
          className="w-full h-full z-10"
          objectFit="cover"
          objectPosition={stereaImagePositionToCSS(
            data.strapiPage.posizione_copertina
          )}
          alt={data.strapiPage.Copertina?.alternativeText}
        />
        <div className="sterea-center-column h-3/4-screen z-20 flex justify-center items-center">
          <h1 className="w-11/12 text-white text-xl lg:text-5xl leading-normal text-center font-bold">
            {data.strapiPage.Titolo}
          </h1>
        </div>
      </div>

      <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column flex flex-col items-center py-12 px-min">
          <div
            dangerouslySetInnerHTML={{ __html: data.strapiPage.Contenuto }}
            className="sterea-article-container sterea-content-container"
          ></div>
          {data.strapiPage.contenuto.map((contenuto) => {
            if (contenuto.contenuto) {
              return (
                <div
                  dangerouslySetInnerHTML={{ __html: contenuto.contenuto }}
                  className="sterea-article-container sterea-content-container"
                ></div>
              );
            } else if (contenuto.testo) {
              return (
                <div className="sterea-article-container sterea-content-container flex flex-wrap items-center">
                  <div className={`w-full mb-3`}>
                    <StereaImage
                      images={contenuto.immagine}
                      sizes={"100vw"}
                      alt={contenuto.immagine.alternativeText}
                    />
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: contenuto.testo }}
                    className={`w-full sterea-article-container sterea-content-container`}
                  ></div>
                </div>
              );
            } else if (contenuto.immagine) {
              return (
                <div className="sterea-article-container sterea-content-container">
                  <StereaImage
                    images={contenuto.immagine}
                    sizes={"100vw"}
                    alt={contenuto.immagine.alternativeText}
                  />
                </div>
              );
            }
          })}
          {/* <ReactMarkdown transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`}>{data.strapiPage.Contenuto}</ReactMarkdown> */}
        </div>
      </div>

      <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column flex justify-center py-12 px-min">
          {/* <EmailShareButton url={"https://google.com"}>abc</EmailShareButton> */}
          <FacebookShareButton
            url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`}
          >
            <FacebookIcon size={32} className="mr-3" round={true} />
          </FacebookShareButton>
          {/* <HatenaShareButton url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`}>abc</HatenaShareButton>
          <InstapaperShareButton url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`}>abc</InstapaperShareButton>
          <LineShareButton url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`}>abc</LineShareButton>
          <LinkedinShareButton url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`}>abc</LinkedinShareButton>
          <LivejournalShareButton url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`}>abc</LivejournalShareButton>
          <MailruShareButton url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`}>abc</MailruShareButton>
          <OKShareButton url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`}>abc</OKShareButton> */}
          {/* <PinterestShareButton url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`} media={data.strapiPage?.Copertina?.localFile.absolutePath}>
            <PinterestIcon size={32} className="mr-3" round={true} />
          </PinterestShareButton> */}
          {/* <PocketShareButton url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`}>abc</PocketShareButton>
          <RedditShareButton url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`}>abc</RedditShareButton>
          <TelegramShareButton url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`}>abc</TelegramShareButton>
          <TumblrShareButton url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`}>abc</TumblrShareButton> */}
          <TwitterShareButton
            url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`}
          >
            <TwitterIcon size={32} className="mr-3" round={true} />
          </TwitterShareButton>
          {/* <ViberShareButton url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`}>abc</ViberShareButton>
          <VKShareButton url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`}>abc</VKShareButton> */}
          <WhatsappShareButton
            url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`}
          >
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
          {/* <WorkplaceShareButton url={`https://stereamedicaltravel.it/post/${data.strapiPage.Permalink}`}>abc</WorkplaceShareButton> */}
        </div>
      </div>

      <NewsletterBanner />
    </Layout>
  );
};

export default SinglePage;
